import React, { useEffect } from 'react'
import { useMsal } from '@azure/msal-react'
import { useRouter } from 'next/router'
import { Box, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    container: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
})

export default function HomePage() {
    const router = useRouter()
    const classes = useStyles()
    const { instance } = useMsal()

    useEffect(() => {
        if (!instance) return
        instance.handleRedirectPromise().then((res) => {
            if (res) return
            router.push('/company')
        })
    }, [instance, router])

    return (
        <Box className={classes.container}>
            <CircularProgress />
        </Box>
    )
}
